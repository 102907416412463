import {
  ConnectIntegrationInputType,
  ConnectIntegrationInputTypeValidationBuilder,
  ConnectKingFinanceIntegrationInputType,
  ConnectKingFinanceIntegrationInputTypeValidationBuilder,
  ConnectReeleezeeIntegrationInputType,
  ConnectReeleezeeIntegrationInputTypeValidationBuilder,
  ConnectSchoutenZekerheidIntegrationInputType,
  ConnectSchoutenZekerheidIntegrationInputTypeValidationBuilder,
  ConnectVerhuisOffertesIntegrationInputType,
  ConnectVerhuisOffertesIntegrationInputTypeValidationBuilder,
  ConnectWhatsAppIntegrationInputType,
  ConnectWhatsAppIntegrationInputTypeValidationBuilder,
  ConnectYukiIntegrationInputType,
  ConnectYukiIntegrationInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import {
  ConnectKingFinanceForm,
  ConnectReeleezeeForm,
  ConnectScanMoversForm,
  ConnectSchoutenZekerheidForm,
  ConnectVerhuisOffertesForm,
  ConnectWhatsAppForm,
  ConnectYukiForm,
} from '@bas/integration-domain/web/molecules';
import schoutenZekerheid from '@bas/ui/assets/images/integrationLogos/erkende-verhuizers.png';
import exactOnline from '@bas/ui/assets/images/integrationLogos/exactOnline.svg';
import googleCalendar from '@bas/ui/assets/images/integrationLogos/googleCalendar.svg';
import king from '@bas/ui/assets/images/integrationLogos/king.png';
import klantenvertellen from '@bas/ui/assets/images/integrationLogos/klantenvertellen.png';
import mollie from '@bas/ui/assets/images/integrationLogos/mollie.svg';
import octopus from '@bas/ui/assets/images/integrationLogos/octopus.png';
import reeleezee from '@bas/ui/assets/images/integrationLogos/reeleezee.png';
import scanMovers from '@bas/ui/assets/images/integrationLogos/scanMovers.svg';
import snelStart from '@bas/ui/assets/images/integrationLogos/snelStart.png';
import twinfield from '@bas/ui/assets/images/integrationLogos/twinfield.png';
import verhuisOffertes from '@bas/ui/assets/images/integrationLogos/verhuisOffertes.svg';
import website from '@bas/ui/assets/images/integrationLogos/website.png';
import whatsApp from '@bas/ui/assets/images/integrationLogos/whatsApp.svg';
import yukiVisma from '@bas/ui/assets/images/integrationLogos/yukiVisma.svg';
import { ProviderType } from '@bas/value-objects';
import { ReactNode } from 'react';
import * as Yup from 'yup';

export const getProviderName = (providerType: ProviderType): string => {
  switch (providerType) {
    case ProviderType.EXACT_ONLINE:
      return 'Exact Online';
    case ProviderType.EXACT_GLOBE:
      return 'Exact Globe';
    case ProviderType.TWINFIELD:
      return 'Twinfield';
    case ProviderType.SNEL_START:
      return 'SnelStart';
    case ProviderType.REELEEZEE:
      return 'Reeleezee';
    case ProviderType.IMUIS:
      return 'King Finance';
    case ProviderType.SCHOUTEN_ZEKERHEID:
      return 'Garantiecertificaat';
    case ProviderType.MOLLIE:
      return 'Mollie';
    case ProviderType.KLANTENVERTELLEN:
      return 'Klantenvertellen';
    case ProviderType.WEBSITE:
      return 'Website integratie';
    case ProviderType.WHATSAPP:
      return 'WhatsApp';
    case ProviderType.GOOGLE_CALENDAR:
      return 'Google Calendar';
    case ProviderType.VERHUIS_OFFERTES:
      return 'VerhuisOffertes.com';
    case ProviderType.SCAN_MOVERS:
      return 'ScanMovers';
    case ProviderType.YUKI:
      return 'Yuki';
    case ProviderType.OCTOPUS:
      return 'Octopus';
    default:
      return providerType;
  }
};

export const getProviderLogo = (providerType: ProviderType): string => {
  switch (providerType) {
    case ProviderType.EXACT_ONLINE:
      return exactOnline;
    case ProviderType.EXACT_GLOBE:
      return exactOnline;
    case ProviderType.TWINFIELD:
      return twinfield;
    case ProviderType.SNEL_START:
      return snelStart;
    case ProviderType.REELEEZEE:
      return reeleezee;
    case ProviderType.IMUIS:
      return king;
    case ProviderType.SCHOUTEN_ZEKERHEID:
      return schoutenZekerheid;
    case ProviderType.MOLLIE:
      return mollie;
    case ProviderType.KLANTENVERTELLEN:
      return klantenvertellen;
    case ProviderType.WEBSITE:
      return website;
    case ProviderType.VERHUIS_OFFERTES:
      return verhuisOffertes;
    case ProviderType.WHATSAPP:
      return whatsApp;
    case ProviderType.GOOGLE_CALENDAR:
      return googleCalendar;
    case ProviderType.SCAN_MOVERS:
      return scanMovers;
    case ProviderType.YUKI:
      return yukiVisma;
    case ProviderType.OCTOPUS:
      return octopus;
    default:
      return providerType;
  }
};

export const getFormInputType = (
  providerType: ProviderType
): {
  validationSchema: Yup.ObjectSchema<ConnectIntegrationInputType>;
  defaultValues:
    | ConnectIntegrationInputType
    | ConnectReeleezeeIntegrationInputType
    | ConnectSchoutenZekerheidIntegrationInputType
    | ConnectVerhuisOffertesIntegrationInputType
    | ConnectKingFinanceIntegrationInputType
    | ConnectYukiIntegrationInputType
    | ConnectWhatsAppIntegrationInputType
    | (ConnectIntegrationInputType & {
        apiKeys: unknown[];
        apiKeyPerIdentity: false;
        apiKey: string;
      });
} => {
  switch (providerType) {
    case ProviderType.REELEEZEE:
      return {
        validationSchema:
          ConnectReeleezeeIntegrationInputTypeValidationBuilder(),
        defaultValues: {
          provider: providerType,
          username: '',
          password: '',
        },
      };
    case ProviderType.IMUIS:
      return {
        validationSchema:
          ConnectKingFinanceIntegrationInputTypeValidationBuilder(),
        defaultValues: {
          provider: providerType,
          environmentCode: '',
        },
      };
    case ProviderType.SCHOUTEN_ZEKERHEID:
      return {
        validationSchema:
          ConnectSchoutenZekerheidIntegrationInputTypeValidationBuilder(),
        defaultValues: {
          provider: providerType,
          apiKey: '',
          apiKeyPerIdentity: false,
          apiKeys: [],
        },
      };
    case ProviderType.VERHUIS_OFFERTES:
      return {
        validationSchema:
          ConnectVerhuisOffertesIntegrationInputTypeValidationBuilder(),
        defaultValues: { provider: providerType, sharedSecret: '' },
      };
    case ProviderType.SCAN_MOVERS:
      return {
        validationSchema:
          ConnectVerhuisOffertesIntegrationInputTypeValidationBuilder(),
        defaultValues: { provider: providerType, sharedSecret: '' },
      };
    case ProviderType.YUKI:
      return {
        validationSchema: ConnectYukiIntegrationInputTypeValidationBuilder(),
        defaultValues: { provider: providerType, apiKey: '' },
      };
    case ProviderType.WHATSAPP:
      return {
        validationSchema:
          ConnectWhatsAppIntegrationInputTypeValidationBuilder(),
        defaultValues: {
          provider: providerType,
          clientId: '',
          clientSecret: '',
          token: '',
        },
      };
    default:
      return {
        validationSchema: ConnectIntegrationInputTypeValidationBuilder(),
        defaultValues: { provider: providerType },
      };
  }
};

export const getFormComponent = (
  providerType: ProviderType
): ReactNode | undefined => {
  switch (providerType) {
    case ProviderType.REELEEZEE:
      return <ConnectReeleezeeForm />;
    case ProviderType.IMUIS:
      return <ConnectKingFinanceForm />;
    case ProviderType.SCHOUTEN_ZEKERHEID:
      return <ConnectSchoutenZekerheidForm />;
    case ProviderType.VERHUIS_OFFERTES:
      return <ConnectVerhuisOffertesForm />;
    case ProviderType.SCAN_MOVERS:
      return <ConnectScanMoversForm />;
    case ProviderType.YUKI:
      return <ConnectYukiForm />;
    case ProviderType.WHATSAPP:
      return <ConnectWhatsAppForm />;
    default:
      return undefined;
  }
};
