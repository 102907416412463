import {
  IntegrationByIntegrationIdRequestInvalidator,
  BatchDataByIntegrationIdRequestInvalidator,
  IntegrationsRequestInvalidator,
} from './lib';

export const queryInvalidators = [
  IntegrationByIntegrationIdRequestInvalidator,
  BatchDataByIntegrationIdRequestInvalidator,
  IntegrationsRequestInvalidator,
];
